<template>
  <page-container :title="liftpropertytypeName" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div v-if="!liftpropertytype" class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item v-if="!liftpropertytype" label="属性类别" prop="liftpropertytype">
              <a-select v-model="queryParams.liftpropertytype">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option v-for="(item, index) in liftPropTypes" :key="index" :value="item.value">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <!-- <a-form-model-item :label="liftpropertytypeName" prop="value">
              <a-input v-model.trim="queryParams.value" placeholder="请输入"></a-input>
            </a-form-model-item> -->
            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <!-- <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button> -->
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{liftpropertytypeName}}</h3>
          <div class="table-btns">
            <a-button type="primary" @click="showModal('add')"><a-icon type="plus"></a-icon>新建</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="liftpropertyid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="liftpropertytype" slot-scope="text">
            <span class="text">{{liftPropTypesMap[text]||''}}</span>
          </span>
          <span slot="operation" slot-scope="value, record, index">
            <a-button type="link" size="small" @click="showModal('detail', value, record, index)">详情</a-button>
            <a-button type="link" size="small" @click="showModal('edit', value, record, index)">修改</a-button>
            <a-button type="link" size="small" @click="deleteConfirm(value, record, index)">删除</a-button>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData" @get-operation-result="getTableData" :liftpropertytype="liftpropertytype"></add-or-edit-modal>
  </page-container>
</template>

<script>
import { getItemFromArrayByKey } from 'U'
import store from '@/store'
import moment from 'moment'
import liftPropTypes from '@/json/liftPropTypes'
import pagination from '@/mixins/pagination'
import { getLiftListByCondition, deleteLiftproperty } from 'A/jcgn.js'
import addOrEditModal from './addOrEditModal'

export default {
  name: 'elevatorProperties',
  mixins: [pagination],
  components: {
    addOrEditModal
  },
  props: ['liftpropertytype'],
  data() {
    return {
      moment,
      liftPropTypes,
      queryParams: {
        liftpropertytype: '',
        value: '',
      },
      queryAreaSelected: [],
      queryInstallDate: null,
      tableColumns: [
        {
          title: '属性类别',
          dataIndex: 'liftpropertytype',
          key: 'liftpropertytype',
          ellipsis: true,
          scopedSlots: { customRender: 'liftpropertytype' }
        },
        {
          title: '属性值',
          dataIndex: 'value',
          key: 'value',
          ellipsis: true,
        },
        // {
        //   title: '制造商代码',
        //   dataIndex: 'manufacturernum',
        //   key: 'manufacturernum',
        //   ellipsis: true,
        // },
        // {
        //   title: '联系人',
        //   dataIndex: 'contactperson',
        //   key: 'contactperson',
        //   ellipsis: true,
        // },
        // {
        //   title: '联系地址',
        //   dataIndex: 'contactaddress',
        //   key: 'contactaddress',
        //   ellipsis: true,
        // },
        // {
        //   title: '联系电话',
        //   dataIndex: 'contactphone',
        //   key: 'contactphone',
        //   ellipsis: true,
        // },
        {
          title: '描述',
          dataIndex: 'liftdesc',
          key: 'liftdesc',
          ellipsis: true,
        },
        {
          title: '操作',
          key: 'operation',
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
    }
  },
  computed: {
    liftPropTypesMap() {
      let result = {};
      this.liftPropTypes.forEach(item => {
        result[item.value] = item.name;
      })
      return result;
    },
    liftpropertytypeName() {
      if(this.liftpropertytype && this.liftPropTypesMap[this.liftpropertytype]) {
        return this.liftPropTypesMap[this.liftpropertytype]
      }else {
        return '电梯属性'
      }
    },
    breadcrumb() {
      return [
        {
          name: '基础功能管理',
          path: ''
        },
        {
          name: '基础信息管理',
          path: ''
        },
        {
          name: this.liftpropertytypeName,
          path: ''
        },
      ]
    }
  },
  watch: {
    queryAreaSelected(val) {
      if(val && val.length) {
        this.queryParams.areacode = val[val.length-1];
      }else {
        this.queryParams.areacode = '';
      }
    },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.installstarttime = start;
      this.queryParams.installendtime = end;
    },
  },
  beforeRouteEnter(to, from, next) {
    let toTab = getItemFromArrayByKey(store.state.main.tabs, 'id', to.name);
    if(!toTab) {
      store.commit('delKeepAlive', to.name)
    }
    next()
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.initPageInfo();
      this.getTableData();
    },
    initPageInfo() {
      if(this.liftpropertytype) {
        this.queryParams.liftpropertytype = this.liftpropertytype;
      }
    },
    resetQueryParams() {
      this.queryAreaSelected = [];
      this.$refs.queryForm.resetFields();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getLiftListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    deleteConfirm(value, record) {
      this.$confirm({
        // title: '',
        content: '确定要删除该数据?',
        okText: '确定',
        cancelText: '取消',
        onOk:() => {
          this.delete(record.liftpropertyid);
        },
        onCancel() {},
      });
    },
    delete(liftpropertyid) {
      this.showLoading();
      if(liftpropertyid) {
        let params = {
          liftpropertyid
        };
        deleteLiftproperty(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    showModal(type, value, record) {
      this.modalShowType = type;
      if(type == 'add') {
        this.modalDetailData = null;
      }else {
        this.modalDetailData = record;
      }
      this.modalVisible = true;
    },
  }
}
</script>

<style lang="scss" scoped>
</style>