export default {
  data() {
    return {
      currentPage: 1,
      pagination: {
        total: 0,
        pageSize: 10,
      },
    }
  },
  methods: {
    pageChange(page, size) {
      this.getTableData();
    },
    onShowSizeChange(page, size) {
      this.pagination.pageSize = size;
      this.getTableData(true);
    },
  }
}