// 基础功能
import store from '@/store'
import http from '@/utils/http'
import qs from 'qs'

let preApi = '';
if (store.state.mock && process.env.NODE_ENV == 'development') {
  preApi = '/mock';
}

// 视频回放
export function getPlayUrl(params) {
  return http({
    url: `${preApi}/playurl/getPlayUrl`,
    method: "post",
    data: params
  })
}

// 维保单位列表查询
export function getUserdepListByCondition(params) {
  return http({
    url: `${preApi}/userdep/getUserdepListByCondition`,
    method: "post",
    data: params
  })
}

// 单位树形列表查询
export function getTreeOfUserDepHasUser(params) {
  return http({
    url: `${preApi}/userdep/getTreeOfUserDepHasUser`,
    method: "post",
    data: params
  })
}

// 维保单位根据父级id查询
export function getChildTreeListByParentId(params) {
  let key = qs.stringify(params);
  // if(store.state.cache.deptMap[key]) {
  //   return new Promise((resolve, reject) => {
  //     resolve(store.state.cache.deptMap[key])
  //   })
  // }else {
  return http({
    url: `${preApi}/userdep/getChildTreeListByParentId`,
    method: "post",
    data: params
  }).then(res => {
    store.commit('cache/addDept', {
      key: key,
      data: res
    })
    return res;
  })
  // }
}
// 维保单位父级单位查询
export function getParentTreeByid(params) {
  return http({
    url: `${preApi}/userdep/getParentTreeByid`,
    method: "post",
    data: params
  })
}
// 新增维保单位
export function addUserdep(params) {
  return http({
    url: `${preApi}/userdep/addUserdep`,
    method: "post",
    data: params
  }).then(res => {
    store.commit('cache/resetDept')
    return res;
  })
}
// 修改维保单位
export function modifyUserdep(params) {
  return http({
    url: `${preApi}/userdep/modifyUserdep`,
    method: "post",
    data: params
  }).then(res => {
    store.commit('cache/resetDept')
    return res;
  })
}
// 维保单位删除
export function deleteUserdep(params) {
  return http({
    url: `${preApi}/userdep/deleteUserdep`,
    method: "post",
    data: params
  }).then(res => {
    store.commit('cache/resetDept')
    return res;
  })
}

// 电梯档案-列表
export function getLiftinfoListByCondition(params) {
  return http({
    url: `${preApi}/lift/getLiftinfoListByCondition`,
    method: "post",
    data: params
  })
}
// 电梯档案-详情
export function getLiftinfoById(params) {
  return http({
    url: `${preApi}/lift/getLiftinfoById`,
    method: "post",
    data: params
  })
}
// 电梯档案-新增
export function addLiftinfo(params) {
  return http({
    url: `${preApi}/lift/addLiftinfo`,
    method: "post",
    data: params
  })
}
// 电梯档案-修改
export function modifyLiftinfo(params) {
  return http({
    url: `${preApi}/lift/modifyLiftinfo`,
    method: "post",
    data: params
  })
}
// 电梯档案-删除
export function deleteLiftinfo(params) {
  return http({
    url: `${preApi}/lift/deleteLiftinfo`,
    method: "post",
    data: params
  })
}
// 电梯档案-查询审批记录
export function getApproveListByCondition(params) {
  return http({
    url: `${preApi}/lift/getApproveListByCondition`,
    method: "post",
    data: params
  })
}
// 电梯档案-查询待审批电梯列表
export function findLiftinfoListByCheckCondition(params) {
  return http({
    url: `${preApi}/lift/findLiftinfoListByCheckCondition`,
    method: "post",
    data: params
  })
}
// 电梯档案-审批
export function approveLift(params) {
  return http({
    url: `${preApi}/lift/approveLift`,
    method: "post",
    data: params
  })
}
// 电梯档案-检测
export function addExamineinfo(params) {
  return http({
    url: `${preApi}/lift/addExamineinfo`,
    method: "post",
    data: params
  })
}
// 电梯档案-查询检测记录
export function findExamineListByCondition(params) {
  return http({
    url: `${preApi}/lift/findExamineListByCondition`,
    method: "post",
    data: params
  })
}

// 电梯属性-列表
export function getLiftListByCondition(params) {
  let key = qs.stringify(params);
  if (store.state.cache.liftpropertyMap[key]) {
    return new Promise((resolve, reject) => {
      resolve(store.state.cache.liftpropertyMap[key])
    })
  } else {
    return http({
      url: `${preApi}/lift/getLiftListByCondition`,
      method: "post",
      data: params
    }).then(res => {
      store.commit('cache/addLiftproperty', {
        key: key,
        data: res
      })
      return res;
    })
  }
}
export function getLiftinfoPhoneById(params) {
  return http({
    url: `${preApi}/lift/getLiftinfoPhoneById`,
    method: "post",
    data: params
  })
}
// 电梯属性-新增
export function addLiftproperty(params) {
  return http({
    url: `${preApi}/lift/addLiftproperty`,
    method: "post",
    data: params
  }).then(res => {
    store.commit('cache/resetLiftproperty')
    return res;
  })
}
// 电梯属性-修改
export function modifyLiftproperty(params) {
  return http({
    url: `${preApi}/lift/modifyLiftproperty`,
    method: "post",
    data: params
  }).then(res => {
    store.commit('cache/resetLiftproperty')
    return res;
  })
}
// 电梯属性-删除
export function deleteLiftproperty(params) {
  return http({
    url: `${preApi}/lift/deleteLiftproperty`,
    method: "post",
    data: params
  }).then(res => {
    store.commit('cache/resetLiftproperty')
    return res;
  })
}

// 电梯属性-删除
export function getPluginParam(params) {
  return http({
    url: `${preApi}/playurl/getPluginParam`,
    method: "post",
    data: params
  })
}

// 7.1.37.	添加区域表
export function addArea(params) {
  return http({
    url: `${preApi}/area/addArea`,
    method: "post",
    data: params
  })
}
// 7.1.38.	更新区域表
export function modifyArea(params) {
  return http({
    url: `${preApi}/area/modifyArea`,
    method: "post",
    data: params
  })
}
// 7.1.39.	删除区域表
export function deleteArea(params) {
  return http({
    url: `${preApi}/area/deleteArea`,
    method: "post",
    data: params
  })
}

//联动参数
export function addEventLinkage(params) {
  return http({
    url: `${preApi}/lift/addEventLinkage`,
    method: "post",
    data: params
  })
}
export function updateEventLinkage(params) {
  return http({
    url: `${preApi}/lift/updateEventLinkage`,
    method: "post",
    data: params
  })
}
export function getEventLinkageListByCondition(params) {
  return http({
    url: `${preApi}/lift/getEventLinkageListByCondition`,
    method: "post",
    data: params
  })
}

// 电梯档案-列表
export function getElevatorInfoListByCondition(params) {
  return http({
    url: `${preApi}/elevator/getElevatorInfoListByCondition`,
    method: "post",
    data: params
  })
}
// 电梯档案-详情
export function getElevatorInfoById(params) {
  return http({
    url: `${preApi}/elevator/getElevatorInfoById`,
    method: "post",
    data: params
  })
}
// 电梯档案-新增
export function addElevatorInfo(params) {
  return http({
    url: `${preApi}/elevator/addElevatorInfo`,
    method: "post",
    data: params
  })
}
// 电梯档案-修改
export function modifyElevatorInfo(params) {
  return http({
    url: `${preApi}/elevator/modifyElevatorInfo`,
    method: "post",
    data: params
  })
}
// 电梯档案-删除
export function deleteElevatorInfo(params) {
  return http({
    url: `${preApi}/elevator/deleteElevatorInfo`,
    method: "post",
    data: params
  })
}

// 电梯档案-转化
export function transformLiftInfo(params) {
  return http({
    url: `${preApi}/elevator/transformLiftInfo`,
    method: "post",
    data: params
  })
}